<template>
  <div class="banner">
    <h1>Bringing Holmes Country to Your Neighborhood</h1>
    <p style="margin-top: 10px">
      We provide premium Troyer's deli Meats and cheese<br />
      from the heart of Ohio's Amish Country right to your neighboorhood.
    </p>
    <p>
      We carry meats, cheese, spices, cooking items, candy, snacks, and more!
    </p>
    <router-link type="button" class="btn" to="/menu"
      >Check Out Our Menu</router-link
    >
  </div>
</template>

<script>
export default {
  name: "home_banner",
};
</script>

<style scoped>
.banner {
  background: black;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
    url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyer-Trail-Bologna.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 80px 40px;

  color: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 20px 5px #0d0d0d;
}


p {
  margin-bottom: 10px;
  font-size: 18px;
}

.btn {
  padding: 10px 4%;
  margin-top: 30px;
  margin-bottom: 0;
  background: rgba(128, 0, 0, 0.9);
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: #ffffff;
  box-shadow: none;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}

.btn:hover {
  transform: scale(1.05);
  color: #ffffff;
}

@media screen and (max-width: 768px){
  .banner {
    padding: 40px 20px;
    margin: 0 5% !important;
    color: #ffffff;
    text-align: center;
    box-shadow: 0px 0px 20px 5px #0d0d0d;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .btn {
    padding: 10px 25px;
  }
}
</style>
