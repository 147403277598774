<template>
  <div id="main">
    <topBanner />
    <LocationBlock style="margin-top: 80px" />
    <LikeOnFacebookRow style="margin-top: 120px" />
    <CTA style="margin: 140px 10% 80px" />
  </div>
</template>

<script>
import topBanner from "@/components/Jumbotrons/contact_banner.vue";
import LocationBlock from "@/components/Info/LocationBlock.vue";
import LikeOnFacebookRow from "@/components/LikeOnFacebook.vue";
import CTA from "@/components/Jumbotrons/contactCTA.vue";

export default {
  name: "ContactUs",
  components: {
    topBanner,
    LocationBlock,
    LikeOnFacebookRow,
    CTA,
  },
};
</script>

<style scoped>
#main {
  margin: 0;
}
.flex-content {
  display: flex;
  margin: 140px 10%;
}
.flex-content .content {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content > p:last-child {
  margin-bottom: 0;
}
.content.right {
  margin-left: 60px;
}
.content.left {
  margin-right: 60px;
  text-align: right;
}
.align-center {
  text-align: center;
}
.contained {
  margin: 80px 0;
  padding: 120px 10%;
}
.contained > p:last-child {
  margin-bottom: 0;
}
.contained.red {
  background-color: rgba(128, 0, 0, 0.9);
  color: #ffffff;
}
</style>
