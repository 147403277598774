<template>
  <div id="main">
    <div id="menu">
      <div class="pie parallax"></div>

      <BakedProducts />
      <CTA style="margin-top: 80px" />
    </div>
  </div>
</template>

<script>
import BakedProducts from "@/components/Menu/BakedProducts.vue";
import CTA from "@/components/Jumbotrons/pie_landingCTA.vue";

export default {
  name: "mainPies",
  components: {
    BakedProducts,
    CTA,
  },
  methods: {
    scrollToElement(element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>

<style scoped>
#menu {
  text-align: center;
}
h1 {
  border-bottom: 1px solid #000000;
  margin: 0 40%;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 40px;
}
hr {
  margin: 40px 0;
}

.parallax {
  /* Set a specific height */
  min-height: 150px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pie.parallax {
  background-image: url("https://scontent.fpit1-1.fna.fbcdn.net/v/t1.6435-9/126951419_2151840751619068_7552928783587874900_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=730e14&_nc_ohc=FtrkIXnFWwgAX-urRfM&_nc_ht=scontent.fpit1-1.fna&oh=693eefbfe0399413bcbd6c8faa664206&oe=61A298F3");
  margin: 60px 0;
  /* https://scontent.fpit1-1.fna.fbcdn.net/v/t31.18172-8/12309582_705758922893932_622196077774973703_o.jpg?_nc_cat=110&ccb=1-5&_nc_sid=9267fe&_nc_ohc=X4TccSYPFWYAX-bd4b5&_nc_ht=scontent.fpit1-1.fna&oh=2a01bd43d2fe86873495cb33071626dc&oe=61A50D54 */
}
</style>
