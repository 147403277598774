<template>
  <div id="main" style="margin: 0">
    <div id="home">
      <Banner style="margin: 0 10%" />
      <SpecialsTemplate style="margin-top: 120px" />
      <MeatandCheeseRow style="margin: 130px 0" />
      <PieInfoRow style="margin-top: 100px" />
      <LocationBlock style="margin-bottom: 120px; margin-top: 60px" />
      <LikeOnFacebookRow />
      <!-- <CategoryRow /> -->
      <CTA style="margin: 120px 10%" />
    </div>

    <ScrollTopArrow />
  </div>
</template>

<script>
import ScrollTopArrow from "@/components/ScrollTopArrow.vue";
import Banner from "@/components/Jumbotrons/home_banner.vue";
import SpecialsTemplate from "@/components/WeeklySpecials/template.vue";
import PieInfoRow from "@/components/WeeklySpecials/PieCTA.vue";
import MeatandCheeseRow from "@/components/WeeklySpecials/meatandcheeseCTA.vue";
import LocationBlock from "@/components/Info/LocationBlock.vue";
import LikeOnFacebookRow from "@/components/LikeOnFacebook.vue";
// import CategoryRow from "@/components/Info/CategoryBlock.vue";
import CTA from "@/components/Jumbotrons/contactCTA.vue";

export default {
  name: "Home",
  components: {
    ScrollTopArrow,
    LocationBlock,
    LikeOnFacebookRow,
    Banner,
    SpecialsTemplate,
    PieInfoRow,
    MeatandCheeseRow,
    // CategoryRow,
    CTA,
  },
  methods: {
    scrollToElement(element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>

<style scoped>
#home {
  margin-top: 100px;
}

@media screen and (max-width: 768px){
  #home{
    margin-top: 60px;
  }
}
</style>
