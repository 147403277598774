<template>
  <div class="banner">
    <div class="menuItemContainer" style="margin-top: 0">
      <div class="menuItemTitle">
        <h3>Contact Us</h3>
      </div>
    </div>
    <p id="grabme" style="margin-top: 60px">Need help, have a question or comment?</p>
    <p style="margin-bottom: 40px">Ready to place your order?</p>
    <p style="margin-bottom: 0">
      Call us at
      <a class="red-link" href="tel:+1(330)830-3333">(330)830-3333</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "contact_banner",
};
</script>

<style scoped>
.banner {
  background: white;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyer-Trail-Bologna.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 80px 40px;

  color: black;
  text-align: center;
  box-shadow: 0px 0px 20px 5px #0d0d0d;
  margin: 120px 10% 0;
}

h2 {
  border-bottom: 1px solid #8c8c8c;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

p {
  margin-bottom: 10px;
  font-size: 20px;
}

.btn {
  padding: 10px 4%;
  margin-top: 30px;
  margin-bottom: 0;
  background: rgba(128, 0, 0, 0.9);
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: #ffffff;
  box-shadow: none;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}

.btn:hover {
  transform: scale(1.05);
  color: #ffffff;
}

@media screen and (max-width: 768px){
  .banner{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom bottom;
    background-position: center center;
    padding: 80px 40px;
    margin: 80px 5% 30px !important;
  }
  p {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .btn {
    padding: 10px 25px;
  }
  #grabme{
    margin-top: 0 !important;
  }
  .menuItemContainer{
    margin: 60px 0;
  }
}
</style>
