<template>
  <div id="main">
    <div id="pie_landing">
      <div class="pie parallax"></div>

      <div class="menuItemContainer">
        <div class="menuItemTitle">
          <h3>6inch Pies</h3>
        </div>

        <div style="margin-top: 80px" class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Cherry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Cherry Bomb</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>BlueBerry</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Blackberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Raspberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Pecan</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Elderberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Black Raspberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Rhubarb</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Rhubarb Crumb</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Apple</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Dutch Apple</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Peach</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Raisins</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>German Chocolate</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Oatmeal</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Pumpkin</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Custard</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item hidden"></div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Razzleberry<br />Triple Berry</h3>
            </div>
          </div>

          <div class="pie_item hidden"></div>
        </div>
      </div>
    </div>

    <CTA style="margin-top: 120px" />
    <OrderNow />
    <ScrollTopArrow />
  </div>
</template>

<script>
import OrderNow from "@/components/OrderNowComponent.vue";
import ScrollTopArrow from "@/components/ScrollTopArrow.vue";
import CTA from "@/components/Jumbotrons/pie_landingCTA.vue";

export default {
  name: "sixPie",
  components: {
    OrderNow,
    ScrollTopArrow,
    CTA,
  },
};
</script>

<style scoped>
.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
.bottom-right{
  bottom: 90px;
}
.itemsContainer > .item:nth-child(odd) {
  padding-right: 60px;
}
.itemsContainer > .item:nth-child(even) {
  padding-left: 60px;
}
.item {
  flex-basis: 50%;
  text-align: justify;
}
li {
  margin: 20px 0 0 50px;
  padding-left: 10px;
  font-size: 14px;
}
ul {
  padding-left: 0 !important;
}
.pie.parallax {
  background-image: url("https://patch.com/img/cdn20/shutterstock/566479/20191206/030730/styles/patch_image/public/shutterstock-1177700866___06150544142.jpg");
  margin: 60px 0;
}
</style>
