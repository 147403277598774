<template>
  <div class="banner">
    <h2>Ready to Place Your Order?</h2>
    <p style="margin-top: 10px">Give us a call and order your pies today!</p>
    <router-link type="button" class="btn" to="/contact-us"
      >Contact Us</router-link
    >
  </div>
</template>

<script>
export default {
  name: "pieLandingCTA",
};
</script>

<style scoped>
.banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://i.huffpost.com/gen/1481687/images/o-APPLE-PIE-facebook.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 80px 40px;

  color: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 20px 5px #0d0d0d;
}

p {
  margin-bottom: 10px;
  font-size: 20px;
}

.btn {
  padding: 10px 4%;
  margin-top: 30px;
  margin-bottom: 0;
  background: rgba(128, 0, 0, 0.9);
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: #ffffff;
  box-shadow: none;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}

.btn:hover {
  transform: scale(1.05);
  color: #ffffff;
}
</style>
