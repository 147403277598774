<!--
https://troyerscountrymarket.com/wp-content/uploads/2020/07/Bulk-Foods-1024x682.jpg
//
https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyer-Market-Jar-Goods-1024x682.jpg
-->
<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Bulk Food</h3>
    </div>
    <div class="item-description">
      <h4>Stop by and check out our selection of bulk amish goods.</h4>
      <p style="margin: 20px 0 10px">
        We have candy, snacks, baking ingredients and more!
      </p>
      <p>
        Don't see what you're looking for?
        <router-link class="red-link" to="/contact-us">Contact Us</router-link>
        to see if we can find what you need!
      </p>
    </div>
    <div class="prod-row">
      <div class="product">
        <router-link class="img-link" to="/pies/six-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/Candy_in_Damascus.jpg/1200px-Candy_in_Damascus.jpg"
          />
          <div class="cat-title">
            <h6 class="title">Candy</h6>
          </div>
        </router-link>
      </div>
      <div class="product">
        <router-link class="img-link" to="/pies/nine-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            src="https://health.clevelandclinic.org/wp-content/uploads/sites/3/2017/04/sweetSaltyCravings-1149135424_770x533.jpg"
          />
          <div class="cat-title">
            <h6 class="title">Snacks</h6>
          </div>
        </router-link>
      </div>
      <div class="product">
        <router-link class="img-link" to="/pies/nine-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            src="https://assets.epicurious.com/photos/575879b1e99c715b068e0dde/16:9/w_2560%2Cc_limit/shutterstock_361044545.jpg"
          />
          <div class="cat-title">
            <h6 class="title">Baking Ingredients</h6>
          </div>
        </router-link>
      </div>
    </div>
    <div class="prod-row">
      <div class="product">
        <router-link class="img-link" to="/pies/six-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            src="https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyer-Market-Jar-Goods-1024x682.jpg"
          />
          <div class="cat-title">
            <h6 class="title">Jar Goods</h6>
          </div>
        </router-link>
      </div>
      <div class="product">
        <router-link class="img-link" to="/pies/nine-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            src="https://images.ctfassets.net/3s5io6mnxfqz/3pLESMFjNopcam5J5qGcoT/0427544b060a8fd29e3c0b68281d56cf/AdobeStock_191057762.jpeg?fm=jpg&w=900&fl=progressive"
          />
          <div class="cat-title">
            <h6 class="title">Spices &amp; Seasonings</h6>
          </div>
        </router-link>
      </div>
      <div class="product">
        <router-link class="img-link" to="/pies/nine-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            src="https://cdn.whatsgabycooking.com/wp-content/uploads/2019/10/WGC-Curried-Lentil-Soup-2-copy-2-1.jpg"
          />
          <div class="cat-title">
            <h6 class="title">Soups</h6>
          </div>
        </router-link>
      </div>
    </div>
    <div class="prod-row">
      <div class="product">
        <router-link class="img-link" to="/pies/six-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            src="https://cdnimg.webstaurantstore.com/images/guides/692/types-pasta-header.jpg"
          />
          <div class="cat-title">
            <h6 class="title">Noodles &amp; Pasta</h6>
          </div>
        </router-link>
      </div>
      <div class="product">
        <router-link class="img-link" to="/pies/nine-inch-pies">
          <img
            class="cat-img"
            height="185"
            width="380"
            style="object-position: center 70%"
            src="https://www.lansgrupo.com/wp-content/uploads/2018/10/Lan-Raw-Almond-Nuts-01.jpg"
          />
          <div class="cat-title">
            <h6 class="title">Nuts</h6>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BulkFood",
};
</script>

<style scoped>
.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
.itemsContainer > .item:nth-child(odd) {
  padding-right: 60px;
}
.itemsContainer > .item:nth-child(even) {
  padding-left: 60px;
}
.item {
  flex-basis: 50%;
  text-align: justify;
}
li {
  margin: 20px 0 0 50px;
  padding-left: 10px;
  font-size: 14px;
}
ul {
  padding-left: 0 !important;
}

.prod-row {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.product {
  flex-basis: 33%;
  margin: 0 20px;
  opacity: 0.8;
  cursor: pointer;
  transition-duration: 0.3s;
}
.product:hover {
  opacity: 1;
}
.product img {
  width: 100%;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  object-fit: cover;
}
.img-link {
  text-decoration: none;
  color: #000000;
  cursor: pointer !important;
}
.cat-title {
  background: #ffffff;
  padding: 10px 0;
  border: 1px solid black;
}
.title {
  font-family: "Amatic SC", cursive;
  font-size: 36px;
  cursor: pointer;
}
</style>
