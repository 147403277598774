<template>
  <div id="category-row">
    <div class="flex-content" style="margin: 0 10%">
      <div class="content left">
        <h3>Have An Event Planned?</h3>
        <p style="margin-top: 20px">
          We have Meat and Cheese Party Trays for any occassion! Choose from one
          of our pre-selected trays or create your own with the Massillon Tiger Tray to make a tray that you know
          everyone's going to love. Try our favorite: Troyer's
          Delight Party Tray featuring our delicious Trail Bologna Rings paired
          with a variety cheeses.
        </p>
        <p>
          We also offer build your own sandwhich trays, allowing you to create
          the perfect sandwich for your party guests. And don't forget the
          Condiment Tray! This tray includes fresh vegetables to to pair
          perfectly with any sandwich tray, taking your tastebuds on a trip to
          the Ohio countryside.
        </p>
        <p>View our selection of our Party Trays and call to order today!</p>
        <div class="button-row">
          <router-link to="/meat-and-cheese-trays" class="btn white"
            >View Our Party Trays</router-link
          >
          <router-link to="/contact-us" class="btn red"
            >Order Today</router-link
          >
        </div>
      </div>
      <div class="img-container">
        <img
          src="https://media-cdn.tripadvisor.com/media/photo-s/11/ad/fe/a3/deli-meat-trays.jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "weeklySpecialsTemplate",
};
</script>

<style scoped>
#category-row {
  padding: 5px 0;
}
.img-container {
  text-align: center;
  display: flex;
  align-items: center;
  height: 400px;
  flex-basis: 50%;
}
.content {
  flex-basis: 50%;
  display: flex;
  align-content: center;
  color: #000000;
}
.content.left {
  text-align: justify;
}
.img-container img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 400px;
}

.button-row {
  display: flex;
  justify-content: left;
}
.button-row > .btn:first-child {
  margin-right: 10px;
}
.button-row > .btn:last-child {
  margin-left: 10px;
}
.btn {
  flex-basis: 50%;
  padding: 10px 5%;
  margin-top: 10px;
  margin-bottom: 0;
  box-shadow: none;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}
.btn.red {
  background: rgba(128, 0, 0, 0.9);
  border: 2px solid #ffffff;
  color: #ffffff;
}
.btn.white {
  background: #ffffff;
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: rgba(128, 0, 0, 0.9);
}
.btn:hover {
  transform: scale(1.05);
}
@media screen and (max-width: 768px){
  #category-row{
    margin: 80px 0 !important;
  }
  .flex-content{
    margin: 0 5% !important;
  }
  .img-container {
    display: block;
    height: unset;
  }
  .img-container img {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: unset;
  }
}
</style>
