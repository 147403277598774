<template>
  <div id="body">
    <HeaderMegaMenu />
    <div id="gradient" v-if="show">
      <!-- eslint-disable-next-line  -->
    <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderMegaMenu from "@/components/HeaderMegaMenu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  data() {
    return {
      show: false,
    };
  },
  components: {
    HeaderMegaMenu,
    Footer,
  },
  mounted() {
    this.show = true;
  },
};
</script>

<style>
#body {
  min-height: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 95%, #800000 100%);

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
/* 
  background-image: linear-gradient(
      to bottom,
      rgba(97, 0, 0, 0.9) 1%,
      rgba(255, 255, 255, 0.3) 15%
    ),
    url("https://wallpapercave.com/wp/wp2264154.jpg"); */

}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
