<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Soups</h3>
    </div>
    <div class="item-description"></div>
    <div class="itemsContainer">
      <div class="item">
        <h3>Placeholder</h3>
        <p>
          Turkey Breast, Ham, Lacy Baby Swiss Cheese, choice of dressing &amp;
          toppings
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Soups",
};
</script>
