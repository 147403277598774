<template>
  <div id="main">
    <div id="menu">
      <div class="bulk parallax"></div>

      <BulkFood />
      <CTA style="margin-top: 120px" />
    </div>
  </div>
</template>

<script>
import BulkFood from "@/components/Menu/Bulk.vue";
// import CTA from "@/components/Jumbotrons/pie_landingCTA.vue";
import CTA from "@/components/Jumbotrons/contactCTA.vue";


export default {
  name: "mainBulkFood",
  components: {
    BulkFood,
    CTA,
  },
  methods: {
    scrollToElement(element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>

<style scoped>
#menu {
  text-align: center;
}
h1 {
  border-bottom: 1px solid #000000;
  margin: 0 40%;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 40px;
}
hr {
  margin: 40px 0;
}

.parallax {
  /* Set a specific height */
  min-height: 150px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bulk.parallax {
  background-image: url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Bulk-Foods-1024x682.jpg");
  margin: 60px 0;
}
</style>
