<template>
  <div id="main">
    <div id="about">
      <div class="flex-content">
        <div class="img-container">
          <img src="https://i.imgur.com/BohtgKp.jpg?1" />
        </div>
        <div class="content right">
          <h3>Welcome to Troyer's Dutch Market</h3>
          <p style="margin-top: 20px">
            Located on Lincoln Way E in Perry Township, Ohio, Troyer's Dutch
            Market brings the taste of Holmes County right to your neighborhood.
          </p>
          <p>
            We provide quality amish
            baked goods, Troyer's Home Pantry pies, candies and other
            bulk items.
          </p>
          <p>
            Our deli offers a variety of delicious Troyer's Deli Meats and
            Cheeses, as well as subs, salads, wraps and more! Have a large event
            planned? We offer different sizes of
            <router-link class="red-link" to="/meat-and-cheese-trays"
              >meat and cheese party trays</router-link
            >
            to accommodate any party's needs.
          </p>
          <p>
            Stop by or check out our
            <router-link class="red-link" to="/menu">deli menu</router-link> and
            <router-link class="red-link" to="/contact-us"
              >give us a call</router-link
            >
            to order today!
          </p>
        </div>
      </div>

      <ReviewBlock />
      <LocationBlock style="margin-top: 80px" />
      <LikeOnFacebookRow style="margin-top: 120px" />

      <CTA style="margin: 140px 10% 80px" />
    </div>

    <ScrollTopArrow />
  </div>
</template>

<script>
import CTA from "@/components/Jumbotrons/aboutCTA.vue";
import LocationBlock from "@/components/Info/LocationBlock.vue";
import ReviewBlock from "@/components/Info/ReviewBlock.vue";
import LikeOnFacebookRow from "@/components/LikeOnFacebook.vue";
import ScrollTopArrow from "@/components/ScrollTopArrow.vue";

export default {
  name: "AboutUs",
  components: {
    ReviewBlock,
    LocationBlock,
    CTA,
    LikeOnFacebookRow,
    ScrollTopArrow,
  },
  methods: {
    scrollToElement(element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>

<style scoped>
#main {
  margin: 0;
}
#about {
  margin-top: 140px;
}
.flex-content {
  display: flex;
  margin: 140px 10%;
}
.img-container {
  text-align: center;
}
.img-container img {
  width: 100%;
}
.flex-content .img-container {
  flex-basis: 50%;
}
.flex-content .content {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}
.content > p:last-child {
  margin-bottom: 0;
}
.content.right {
  margin-left: 60px;
}

@media screen and (max-width: 768px){
  #about {
    margin-top: 60px;
  }
  .flex-content {
    display: block;
    margin: 60px 5%;
  }
  .content.right {
    margin-left: 0;
    margin-top: 40px;
  }
  .content.left {
    margin-right: 0;
    margin-top: 40px;
    text-align: left;
  }
}
</style>
