<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Perry Special</h3>
    </div>
    <div class="item-description" style="display: none"></div>
    <div class="itemsContainer">
      <div class="image-container">
        <img width="250" src="@/assets/PerrySpecial.png" />
      </div>
      <div class="itemContent">
        <h3>Tray Includes:</h3>
        <div class="item">
          <ul style="flex-basis: 60%">
            <li><h5>Meat</h5></li>
            <li>Virginia Ham</li>
            <li>Honey Roasted Turkey</li>
            <li>Salami or Trail</li>
          </ul>
          <ul style="flex-basis: 40%">
            <li><h5>Cheese</h5></li>
            <li>Swiss</li>
            <li>Colby</li>
            <li>Marble</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="trays">
      <div class="tray-sizes">
        <table>
          <tbody>
            <tr>
              <td>Extra Small<br /><span class="serving">Serves 9-12</span></td>
            </tr>
            <tr>
              <td>$25.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Small<br /><span class="serving">Serves 13-20</span></td>
            </tr>
            <tr>
              <td>$37.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Medium<br /><span class="serving">Serves 30-35</span></td>
            </tr>
            <tr>
              <td>$58.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Large<br /><span class="serving">Serves 35-40</span></td>
            </tr>
            <tr>
              <td>$68.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                Extra Large<br /><span class="serving">Serves 50-55</span>
              </td>
            </tr>
            <tr>
              <td>$88.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerrySpecial",
};
</script>

<style scoped>
.itemsContainer .image-container {
  order: 1;
  flex-basis: 40%;
  text-align: center;
}
.itemContent {
  order: 2;
  margin-left: 60px;
}
.itemsContainer {
  display: flex;
  margin-top: 120px;
  margin-bottom: 100px;
  align-items: center;
}

.itemContent {
  flex-basis: 60%;
  margin-left: 40px;
}

.tray-sizes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tray-sizes table {
  flex-basis: 20%;
  margin: 0 10px;
}
.item {
  display: flex;
}

li {
  margin: 10px 0 0 60px;
  padding-left: 10px;
  font-size: 14px;
}
li:first-child {
  list-style: none;
  margin: 10px 0;
  padding-left: 20px;
}
.trays {
  margin-bottom: 120px;
}


@media screen and (max-width: 768px){
  .itemsContainer{
    display: block;
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .item{
    display: block;
  }
  .itemContent{
    margin-top: 50px;
    margin-left: 20px;
  }
  .item ul{
    padding-left: 10px;
  }
  .item > ul:first-child{
    margin-top: 20px;
  }
  .tray-sizes{
    display: block;
  }
  .tray-sizes table{
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
</style>
