<template>
  <div class="box">
    <div class="home-title">
      <h2 class="title">Weekly Specials</h2>
      <div class="flex" style="margin-top: 40px">
        <div class="featured-flex">
          <div class="img-container">
            <img
              src="https://d2d8wwwkmhfcva.cloudfront.net/800x/d2lnr5mha7bycj.cloudfront.net/product-image/file/large_26f8f3b2-ee44-4a18-a1e5-f3917324ce08.jpg"
            />
          </div>
          <div class="featured-inner">
            <div class="featured-title">
              <h4>Honey Ham</h4>
            </div>
            <div class="featured-price">
              <p>$4.09 / lb</p>
            </div>
          </div>
        </div>
        <div class="featured-flex">
          <div class="img-container">
            <img
              src="https://cdn.shopify.com/s/files/1/0150/0232/products/pearl-valley-marble-slices_1_2400x.jpg?v=1521492073"
            />
          </div>
          <div class="featured-inner">
            <div class="featured-title">
              <h4>Marble Cheese</h4>
            </div>
            <div class="featured-price">
              <p>$3.15 / lb</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sale-date">
        <h5>Sale Prices Good</h5>
        <p>October 25 - Octobebr 30, 2021</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "weeklySpecialsTemplate",
};
</script>

<style scoped>
.box {
  width: 100%;
  padding: 60px 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("https://images.fineartamerica.com/images-medium-large-5/autumn-barn-the-end-of-a-season-mike-savad.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.home-title {
  background: none;
  padding: 60px 60px 0;
  margin: 40px 15% 20px;
  border: 2px solid #999999;
  position: relative;
  color: #000000;
}
.title {
  font-family: "Amatic SC", cursive;
  font-size: 54px;
  cursor: pointer;
  color: #ffffff;
  background: #800000;
  padding: 10px 0;
  position: absolute;
  top: -40px;
  left: 15%;
  right: 15%;
  text-align: center;
}
.flex {
  display: flex;
}
.featured-flex {
  flex-basis: 50%;
  margin: 0 20px;
  text-align: center;
}
.img-container {
  text-align: center;
  height: 200px;
}
.img-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  border-top: 4px solid #000000;
  border-left: 4px solid #000000;
  border-right: 4px solid #000000;
}
.featured-inner {
  padding-top: 20px;
  background: #f2f2f2;
  border: 4px solid #000000;
  border-collapse: collapse;
}
.featured-title h4 {
  font-size: 32px;
}
.featured-price p {
  font-size: 24px;
}
.sale-date {
  color: #ffffff;
  text-align: center;
  margin-top: 40px;
}
@media screen and (max-width: 768px){
  .flex {
    display: block;
    margin-top: 60px !important;
  }
  .featured-flex {
    margin: 40px 0;
  }
  .home-title {
    padding: 20px 20px 0;
    margin: 20px 5% 20px;
    border: none;
    position: relative;
    color: #000000;
  }
  .box {
    padding: 0px 0 40px;
    margin: 40px 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("https://images.fineartamerica.com/images-medium-large-5/autumn-barn-the-end-of-a-season-mike-savad.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
  }
  .title {
    font-size: 48px;
    padding: 10px 30px;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
  }
  .featured-title h4 {
    font-size: 24px;
  }
  .featured-price p {
    font-size: 18px;
  }
}
</style>
