<template>
  <div class="banner">
    <h2>Hungry?</h2>
    <p style="margin-top: 15px">
      Check out our deli menu and give us a call to order today!
    </p>
    <router-link type="button" class="btn" style="margin-top: 15px" to="/menu"
      >View Our Deli Menu</router-link
    >
  </div>
</template>

<script>
export default {
  name: "contactCTA",
};
</script>

<style scoped>
.banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
    url("https://s3.amazonaws.com/bargainhunter/images/articles/troyers-is-the-little-country-market-that-could/Cover_Liz-and-Deal-Troyer_4290.jpg?mtime=20200117141408");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 120px 40px;

  color: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 20px 5px #0d0d0d;
}

p {
  margin-bottom: 10px;
  font-size: 20px;
}

.btn {
  padding: 10px 4%;
  margin-top: 30px;
  margin-bottom: 0;
  background: rgba(128, 0, 0, 0.9);
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: #ffffff;
  box-shadow: none;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}

.btn:hover {
  transform: scale(1.05);
  color: #ffffff;
}

@media screen and (max-width: 768px){
  .banner{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom bottom;
    padding: 80px 40px;
    margin: 80px 5% 30px !important;
  }
  p {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .btn {
    padding: 10px 25px;
  }
}
</style>
