<template>
  <div id="main" style="margin: 0;">
    <div id="specials-page">
      <SpecialsTemplate />
    </div>
  </div>
</template>

<script>
import SpecialsTemplate from "@/components/WeeklySpecials/template.vue";

export default{
  name: "Home",
  components: {
    SpecialsTemplate,
  },
}
</script>

<style scoped>
#specials-page{
  padding-top: 10px;
}
@media screen and (max-width: 768px){
  #specials-page{
    margin-top: 80px;
  }
  .box {margin-top: 10px;}
}
</style>

