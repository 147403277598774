<template>
  <div id="category-row">
    <div class="flex-content" style="margin: 130px 10%">
      <div class="img-container">
        <img
          src="https://scontent-iad3-2.xx.fbcdn.net/v/t1.6435-9/fr/cp0/e15/q65/126951419_2151840751619068_7552928783587874900_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=8024bb&efg=eyJpIjoidCJ9&_nc_ohc=Vh8YijXMqN0AX9cOMN0&tn=FpZuS1wgfzpTM9eF&_nc_ht=scontent-iad3-2.xx&oh=00_AT8Fm_yUtp_v_2dQON4H6Jjow_5XDrktBzy92lf5SM874g&oe=61FB1AD8"
        />
      </div>
      <div class="content right">
        <h3>We Have Troyer's Home Pantry Pies</h3>
        <p style="margin-top: 20px">
          Dedicated to old-fashioned great taste, Troyer's Home Pantry Pies are
          sure to impress anyone you serve a slice to. Freshly baked in Apple
          Creek, Ohio and offered in both 6-inch and 9-inch sizes, these pies
          come in a variety of different flavors, appealing to even the pickest
          of eatters.
        </p>
        <p>
          Stop by the store and view our selection of in-stock pies or call and
          pre-order your pies today to be sure you take your home your favorite.
        </p>
        <div class="button-row">
          <router-link to="/bakedgoods" class="btn white"
            >View Our Pie Selection</router-link
          >
          <router-link to="/contact-us" class="btn red"
            >Order Today</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "weeklySpecialsTemplate",
};
</script>

<style scoped>
#category-row {
  background-color: #800000;
  padding: 5px 0;
}
.img-container {
  text-align: center;
  display: flex;
  align-items: center;
  height: 330px;
  flex-basis: 50%;
}
.content {
  flex-basis: 50%;
  display: flex;
  align-content: center;
  color: #ffffff;
  text-align: justify;
}
.img-container img {
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  height: 300px;
}

.button-row {
  display: flex;
  justify-content: left;
}
.button-row > .btn:first-child {
  margin-right: 10px;
}
.button-row > .btn:last-child {
  margin-left: 10px;
}
.btn {
  flex-basis: 50%;
  padding: 10px 5%;
  margin-top: 10px;
  margin-bottom: 0;
  box-shadow: none;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}
.btn.red {
  background: rgba(128, 0, 0, 0.9);
  border: 2px solid #ffffff;
  color: #ffffff;
}
.btn.white {
  background: #ffffff;
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: rgba(128, 0, 0, 0.9);
}
.btn:hover {
  transform: scale(1.05);
}
@media screen and (max-width: 768px){
  #category-row{
    margin: 80px 0 !important;
    padding: 100px 0;
  }
  .flex-content{
    margin: 0 5% !important;
  }
  .img-container {
    display: block;
    height: unset;
  }
  .img-container img {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: unset;
  }
}
</style>
