<template>
  <div>
    <div
      id="carouselExampleCaptions"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="3000">
          <img
            src="https://i.pinimg.com/originals/df/69/9e/df699eb99ee7541a98b59b4980ff3138.jpg"
            class="d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-none d-md-block">
            <div class="review-stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="review">
              <p>
                This place is pristine and very well organized which means they
                cram a ton of great items at great prices into a small space. I
                go here about once a week for their awesome meats and cheeses
                and I always grab a bunch of other items that just look too good
                to pass up.
              </p>
              <p>
                I should've rated this place poorly so that maybe you won't be
                in my way when I want to go back!
              </p>
            </div>
            <div class="review-author">- Jesse Phillips</div>
          </div>
          <div class="left quote">
            <p>"</p>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img
            src="https://i.pinimg.com/originals/df/69/9e/df699eb99ee7541a98b59b4980ff3138.jpg"
            class="d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-none d-md-block">
            <div class="review-stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="review">
              <p>
                With all of the Amish Country bulk favorites, this is a great
                tenant for Perry Township! The staff is always friendly and
                helpful. This is one of my favorite places to stop for bulk
                candy items before traveling, unique and season flavors of
                jelly, quality meats, delectable pies, and much more. My wife is
                a home baker on the side and being able to pop in for just the
                right amount of specialty baking items goes a long way.
              </p>
            </div>
            <div class="review-author">- Matthew Mays</div>
          </div>
          <div class="left quote">
            <p>"</p>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img
            src="https://i.pinimg.com/originals/df/69/9e/df699eb99ee7541a98b59b4980ff3138.jpg"
            class="d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-none d-md-block">
            <div class="review-stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="review">
              <p>
                Fresh baked pies, bread &amp; muffins! All supplies for home
                made cookies! The best trail bologna around! My son will be home
                from his job outside of the USA &amp; one thing he's looking
                forward to is Troyers Trail Bologna &amp; fresh Baby Swiss
                cheese!
              </p>
            </div>
            <div class="review-author">- Theresa Morrison</div>
          </div>
          <div class="left quote">
            <p>"</p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewBlock",
};
</script>

<style scoped>
.carousel {
  height: 500px;
}
.carousel-item {
  height: 500px;
  background: #000000;
}
.carousel-item img {
  opacity: 0.5;
}
.carousel-caption {
  top: 16%;
  z-index: 5;
}
.reviews {
  text-align: center;
  padding: 120px 10%;
}
.review-stars i {
  font-size: 54px;
  opacity: 0.7;
}
.review-stars i:hover {
  color: #ffffff;
}
.review {
  margin-top: 30px;
  color: #ffffff;
}
.review-author {
  font-family: "Caveat", cursive;
  font-size: 36px;
  margin-top: 20px;
  text-align: right;
}
.quote {
  z-index: 2;
  color: white;
  font-size: 200px;
  opacity: 0.3;
}
.quote.left {
  position: absolute;
  top: 27%;
  left: 13%;
}
.quote p {
  font-family: "Galada", cursive;
}
@media screen and (max-width: 768px){
  .carousel {
    height: 500px;
  }
  .carousel-item {
    height: 500px;
    background: #000000;
  }
  .w-100{
    width: unset !important;
  }
  .d-none{
    display: block !important;
  }
  .carousel-caption {
    top: 14%;
  }
  .review-stars i {
    font-size: 24px;
  }
  .review{
    margin-top: 20px;
  }
  .review p{
    font-size: 12px;
  }
  .review-author {
    font-size: 24px;
    margin-top: 20px;
    text-align: right;
  }
}
</style>
