<template>
  <div id="main">
    <div class="top-content">
      <h4 style="text-transform: uppercase">Need Help?</h4>
      <div class="top-content-text">
        <p>
          Report a problem about the website or contact the developer using the
          form below.
        </p>
        <p>
          Problems or comments regarding Troyer's Dutch Market can be directed
          <router-link to="/contact-us">here</router-link>.
        </p>
      </div>
    </div>
    <div id="contact-dev-page">
      <div class="formTitle">
        <h3>Report a Problem</h3>
      </div>
      <form class="form-container">
        <div class="input">
          <label for="exampleFormControlInput1" class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Name"
          />
        </div>
        <div class="input">
          <label for="exampleFormControlInput1" class="form-label"
            >Email address</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="name@example.com"
          />
        </div>
        <div class="input">
          <label for="exampleFormControlInput1" class="form-label"
            >Phone Number</label
          >
          <input type="tel" class="form-control" />
        </div>
        <div class="input">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Description of Problem</label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
        <div class="button-row">
          <button type="reset" class="btn white">Reset</button>
          <button type="submit" class="btn red">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
#contact-dev-page {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}
.form-container {
  margin: 80px 20% 0;
  padding: 40px;
  border-top: 1px solid rgba(128, 0, 0, 0.9);
}
.formTitle {
  top: 50px;
  display: flex;
  justify-content: center;
  width: 90%;
  position: absolute;
}
.formTitle h3 {
  background: white;
  padding: 0 40px;
  color: rgba(128, 0, 0, 0.9);
  font-family: "Amatic SC", cursive;
  font-size: 45px;
}
.top-content {
  text-align: center;
  position: relative;
  margin-top: 60px;
}
.top-content h4 {
  font-family: "Bree Serif", serif;
  font-size: 200px;
  opacity: 0.1;
  color: rgba(128, 0, 0, 1);
}
.top-content .top-content-text {
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.top-content-text p {
  font-weight: 600;
}
.top-content-text a {
  color: rgba(128, 0, 0, 1);
  text-decoration: none;
}
.top-content-text a:hover {
  text-decoration: underline;
}
form {
  width: 100%;
}
.input {
  margin: 30px 0;
}
.button-row {
  display: flex;
  justify-content: center;
}
.btn {
  flex-basis: 35%;
  padding: 10px 5%;
  margin: 0 20px;
  margin-top: 30px;
  margin-bottom: 0;
  box-shadow: none;
  text-transform: uppercase;
  transition-duration: 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}
.btn.red {
  background: rgba(128, 0, 0, 0.9);
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: #ffffff;
}
.btn.white {
  background: #ffffff;
  border: 2px solid rgba(128, 0, 0, 0.9);
  color: rgba(128, 0, 0, 0.9);
}
.btn:hover {
  transform: scale(1.05);
}
label {
  color: rgba(128, 0, 0, 1);
  opacity: 0.8;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}
.form-control {
  border: 1px solid #000000;
  opacity: 0.4 !important;
  margin-top: 0;
}
textarea.form-control:focus {
  opacity: 1;
  border: 1px solid #800000;
  box-shadow: 0 0 5px 0.25rem #800000;
}

input.form-control:focus {
  opacity: 1;
  border: 1px solid #800000;
  box-shadow: 0 0 5px 0.25rem #800000;
}

input.form-control:disabled {
  background: var(--theme-blackest);
  cursor: not-allowed;
}

input.form-control.form-error {
  border: 1px solid var(--theme-warning-dark);
}

input.form-control.form-error:focus {
  box-shadow: none;
}
</style>
