<template>
  <div id="main">
    <div id="menu">
      <h1>Our Meat and Cheese Party Trays</h1>
      <p style="color: #000000">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam
        vestibulum morbi blandit cursus risus. Cursus risus at ultrices mi
        tempus imperdiet nulla malesuada pellentesque.
      </p>
      <hr />
      <div class="partyTrays" style="margin: 80px 0">
        <p class="jump-info">Jump to a Section in the Menu</p>
        <div class="jump-links deli">
          <a @click="scrollToElement($refs.PerrySpecial)">Perry Special</a>
          <a @click="scrollToElement($refs.PantherGrandSlam)"
            >Panther Grand Slam</a
          >
          <a @click="scrollToElement($refs.MassillonTigerTray)"
            >Massillon Tiger Tray</a
          >
          <a @click="scrollToElement($refs.TroyersDelight)">Troyer's Delight</a>
        </div>
        <div class="jump-links plates">
          <a @click="scrollToElement($refs.MeatTray)">Troyer's Meat Tray</a>
          <a @click="scrollToElement($refs.CheeseTray)">Troyer's Cheese Tray</a>
          <a @click="scrollToElement($refs.CondimentTray)"
            >Troyer's Condiment Tray</a
          >
          <a @click="scrollToElement($refs.SandwichTrays)">Sandwich Trays</a>
        </div>
      </div>
      <div class="cheese parallax"></div>

      <a ref="PerrySpecial"></a>
      <PerrySpecialTray />

      <a ref="PantherGrandSlam"></a>
      <PantherGrandSlamTray />

      <a ref="MassillonTigerTray"></a>
      <MassillonTigerTray />

      <div class="meats-and-cheese parallax"></div>

      <a ref="TroyersDelight"></a>
      <TroyersDelightTray />

      <a ref="MeatTray"></a>
      <TroyersMeatTray />

      <a ref="CheeseTray"></a>
      <TroyersCheeseTray />

      <div class="meat parallax"></div>

      <a ref="CondimentTray"></a>
      <CondimentTray />

      <a ref="SandwichTrays"></a>
      <SubTray />
    </div>

    <OrderNow />
    <ScrollTopArrow />
  </div>
</template>

<script>
import OrderNow from "@/components/OrderNowComponent.vue";
import ScrollTopArrow from "@/components/ScrollTopArrow.vue";

import CondimentTray from "@/components/Menu/Trays/CondimentTray.vue";
import SubTray from "@/components/Menu/Trays/SubTray.vue";
import MassillonTigerTray from "@/components/Menu/Trays/MassillonTiger.vue";
import PantherGrandSlamTray from "@/components/Menu/Trays/PantherGrandSlam.vue";
import PerrySpecialTray from "@/components/Menu/Trays/PerrySpecial.vue";
import TroyersCheeseTray from "@/components/Menu/Trays/TroyersCheese.vue";
import TroyersDelightTray from "@/components/Menu/Trays/TroyersDelight.vue";
import TroyersMeatTray from "@/components/Menu/Trays/TroyersMeat.vue";

export default {
  name: "Menu",
  components: {
    SubTray,
    CondimentTray,
    MassillonTigerTray,
    PantherGrandSlamTray,
    PerrySpecialTray,
    TroyersCheeseTray,
    TroyersDelightTray,
    TroyersMeatTray,
    OrderNow,
    ScrollTopArrow,
  },
  methods: {
    scrollToElement(element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>

<style scoped>
#menu {
  text-align: center;
  margin-top: 80px;
}
.bottom-right{
  bottom: 90px;
}
h1 {
  border-bottom: 1px solid #000000;
  margin: 0 25%;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 40px;
}
hr {
  margin: 40px 0;
}
.partyTrays {
  border: 1px solid rgba(128, 0, 0, 0.2);
  border-radius: 50px;
  padding-top: 40px;

  background: #efefef;
  box-shadow: 0px 0px 20px 5px #0d0d0d;
}
.partyTrays .jump-links {
  margin: 30px 0 50px;
}
.partyTrays h4 {
  font-family: "Amatic SC", cursive;
  color: #000000;
  font-size: 34px;
  opacity: 0.3;
  margin-bottom: 40px;
}

.jump-links {
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  margin: 40px 0 50px;
}
.jump-links a {
  text-decoration: none;
  color: rgba(128, 0, 0, 0.9);
  opacity: 0.7;
  cursor: pointer;
  flex-basis: 33%;
  font-family: "Bree Serif", serif;
  transition-duration: 0.6s;
  transition: all 0.6s;
  font-size: 16px;
}
.jump-info {
  opacity: 0.8;
  margin-bottom: 30px;
}
.jump-links a:hover {
  opacity: 1;
  transform: scale(1.1);
  color: rgba(128, 0, 0, 0.9);
}

.cheese.parallax {
  /* The image used */
  background-image: url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyer-Country-Market-Cheese.jpg");
  margin: 40px 0 60px;
}

.meats-and-cheese.parallax {
  /* The image used */
  background-image: url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyer-Country-Market-Charcuterie-Board.jpg");
  margin: 60px 0;
}

.meat.parallax {
  background-image: url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyers-Meats-Banner.jpg");
  margin: 60px 0;
}

.goods.parallax {
  background-image: url("https://troyerscountrymarket.com/wp-content/uploads/2020/12/Holmes-County-Sampler.jpg");
  margin: 60px 0;
}

@media screen and (max-width: 768px){
  h1{
    margin: 0 20px;
    margin-bottom: 40px;
  }
  .jump-links.deli, .jump-links.plates,
  .jump-links.deli a, .jump-links.plates a{
    display: block;
  }
  .jump-links.deli a, .jump-links.plates a{
    margin-bottom: 25px;
  }
  .jump-links.deli{
    margin-bottom: 25px;
  }
  .jump-links.plates{
    margin-top: 25px;
  }
  .parallax{
    display: none;
  }
  #main{
    margin: 60px 5% 30px;
  }
}
</style>
