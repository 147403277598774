<template>
  <div id="header">
    <img width="350" src="@/assets/troyers-cropped.png" />

    <nav class="navbar navbar-expand-lg navbar-dark bg-nav">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="main_nav">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <router-link class="nav-link" to="/" title="Troyers Dutch Market - Bringing Holmes County to Your Neighborhood">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/menu" title="View Troyer's Dutch Market's Deli Menu">Deli Menu</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/specials" title="Check Out Troyers Dutch Market's Specials"
                >Specials</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/meat-and-cheese-trays" title="Troyers Dutch Market Meat &amp; Cheese Trays"
                >Meat &amp; Cheese Party Trays</router-link
              >
            </li>
            <li class="nav-item dropdown has-megamenu">
              <!-- <router-link class="nav-link dropdown-toggle" to="/bakedgoods" title="Baked Goods at Troyers Dutch Market"
                >Baked Goods</router-link
              > -->
              <div class="dropdown-menu megamenu" role="menu">
                <div class="row g-3 mt-3 justify-center">
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link class="img-link" to="/bakedgoods/cookies">
                        <img
                          height="110"
                          width="585"
                          style="object-position: center"
                          src="https://scontent.fpit1-1.fna.fbcdn.net/v/t31.18172-8/14918830_881100558693100_6694438918575773646_o.jpg?_nc_cat=104&ccb=1-5&_nc_sid=9267fe&_nc_ohc=Zd-yAKxcu98AX_BVWdK&_nc_ht=scontent.fpit1-1.fna&oh=d2fe99b0b400ef3522677521caab652a&oe=61A2AC00"
                        />
                        <div class="cat-title">
                          <h6 class="title">Cookies</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link
                        class="img-link"
                        to="/bakedgoods/pastries-and-bread"
                      >
                        <img
                          height="110"
                          width="585"
                          style="object-position: bottom"
                          src="https://www.supermarketperimeter.com/ext/resources/0806---pastries.jpg?t=1596750671&width=1080"
                        />
                        <div class="cat-title">
                          <h6 class="title">Pastries and Bread</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                  <!-- end col-3 -->
                  <!-- end col-3 -->
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link
                        class="img-link"
                        to="/bakedgoods/six-inch-pies"
                      >
                        <img
                          height="110"
                          width="585"
                          style="object-position: top"
                          src="https://scontent.fpit1-1.fna.fbcdn.net/v/t1.18169-9/27749995_1160654524071034_181113783875996108_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=730e14&_nc_ohc=gNPHm0fratIAX9RKOyt&_nc_ht=scontent.fpit1-1.fna&oh=1fb36d4adfaac337cfa41a8a8ec3b28f&oe=61A26D68"
                        />
                        <div class="cat-title">
                          <h6 class="title">6inch Pies</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                  <!-- end col-3 -->
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link
                        class="img-link"
                        to="/bakedgoods/nine-inch-pies"
                      >
                        <img
                          height="110"
                          width="585"
                          style="object-position: top"
                          src="https://scontent.fpit1-1.fna.fbcdn.net/v/t31.18172-8/17854810_980846992051789_1761571906859901970_o.jpg?_nc_cat=111&ccb=1-5&_nc_sid=9267fe&_nc_ohc=KtQhp1e6m6MAX8Uua4l&tn=p0qkiy4IwTiY3YEh&_nc_ht=scontent.fpit1-1.fna&oh=177d57bbbaf7bc035409886b71588666&oe=61A4DAC2"
                        />
                        <div class="cat-title">
                          <h6 class="title">9inch Pies</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                </div>
                <!-- end row -->
              </div>
              <!-- dropdown-mega-menu.// -->
            </li>
            <li class="nav-item dropdown has-megamenu">
              <!-- <router-link class="nav-link dropdown-toggle" to="/bulkfood" title="Troyers Dutch Market Bulk Amish Goods"
                >Bulk Foods</router-link
              > -->
              <div class="dropdown-menu megamenu" role="menu">
                <div class="row g-3 mt-3 justify-center">
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link class="img-link" to="/bulkfood/candy">
                        <img
                          height="80"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/Candy_in_Damascus.jpg/1200px-Candy_in_Damascus.jpg"
                        />
                        <div class="cat-title">
                          <h6 class="title">Candy</h6>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <!-- end col-3 -->
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link class="img-link" to="/bulkfood/snacks">
                        <img
                          height="80"
                          src="https://health.clevelandclinic.org/wp-content/uploads/sites/3/2017/04/sweetSaltyCravings-1149135424_770x533.jpg"
                        />
                        <div class="cat-title">
                          <h6 class="title">Snacks</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                  <!-- end col-3 -->
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link
                        class="img-link"
                        to="/bulkfood/baking-ingredients"
                      >
                        <img
                          height="80"
                          style="object-position: top"
                          src="https://assets.epicurious.com/photos/575879b1e99c715b068e0dde/16:9/w_2560%2Cc_limit/shutterstock_361044545.jpg"
                        />
                        <div class="cat-title">
                          <h6 class="title">Baking Ingredients</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link class="img-link" to="/bulkfood/jar-goods">
                        <img
                          height="80"
                          style="object-position: top"
                          src="https://troyerscountrymarket.com/wp-content/uploads/2020/07/Troyer-Market-Jar-Goods-1024x682.jpg"
                        />
                        <div class="cat-title">
                          <h6 class="title">Jar Goods</h6>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <!-- end col-3 -->
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link
                        class="img-link"
                        to="/bulkfood/spices-and-seasonings"
                      >
                        <img
                          height="80"
                          src="https://images.ctfassets.net/3s5io6mnxfqz/3pLESMFjNopcam5J5qGcoT/0427544b060a8fd29e3c0b68281d56cf/AdobeStock_191057762.jpeg?fm=jpg&w=900&fl=progressive"
                        />
                        <div class="cat-title">
                          <h6 class="title">Spices &amp; Seasonings</h6>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <!-- end col-3 -->
                </div>
                <!-- end row -->
                <div
                  class="row g-3 mt-3 justify-center"
                  style="margin-bottom: 40px"
                >
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link class="img-link" to="/bulkfood/soup">
                        <img
                          height="80"
                          src="https://cdn.whatsgabycooking.com/wp-content/uploads/2019/10/WGC-Curried-Lentil-Soup-2-copy-2-1.jpg"
                        />
                        <div class="cat-title">
                          <h6 class="title">Soup</h6>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <!-- end col-3 -->
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link class="img-link" to="/bulkfood/pasta">
                        <img
                          height="80"
                          src="https://cdnimg.webstaurantstore.com/images/guides/692/types-pasta-header.jpg"
                        />
                        <div class="cat-title">
                          <h6 class="title">Noodles &amp; Pasta</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                  <!-- end col-3 -->
                  <div class="col-lg-2 col-6 mx-3">
                    <div class="col-megamenu">
                      <router-link class="img-link" to="/bulkfood/nuts">
                        <img
                          height="80"
                          src="https://scontent.fpit1-1.fna.fbcdn.net/v/t31.18172-8/13071804_775159225953901_3934203264948955021_o.jpg?_nc_cat=109&ccb=1-5&_nc_sid=9267fe&_nc_ohc=0QhaurGoKzkAX8klAJV&_nc_ht=scontent.fpit1-1.fna&oh=de0aab3e44375cc1ede0eaec9a9ce45c&oe=61A3A850"
                        />
                        <div class="cat-title">
                          <h6 class="title">Nuts</h6>
                        </div>
                      </router-link>
                    </div>
                    <!-- col-megamenu.// -->
                  </div>
                </div>
                <!-- end row -->
              </div>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/about" title="About Troyers Dutch Market">About</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contact-us" title="Contact Troyers Dutch Market"
                >Contact Us</router-link
              >
            </li>
            <!-- <li class="nav-item dropdown">
				<a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> Dropdown right </a>
			    <ul class="dropdown-menu dropdown-menu-end">
				  <li><a class="dropdown-item" href="#"> Submenu item 1</a></li>
				  <li><a class="dropdown-item" href="#"> Submenu item 2 </a></li>
			    </ul>
			</li> -->
          </ul>
        </div>
        <!-- navbar-collapse.// -->
      </div>
      <!-- container-fluid.// -->
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped>
#header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Images/Header/country_banner.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.navbar {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0;
  align-items: unset;
  padding-top: 15px;
  padding-bottom: 10px;
}

.navbar .megamenu {
  text-align: center;
  background: rgb(255, 255, 255);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, #800000 100%),
    url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Wood-background-footer.jpg");
  /* background: linear-gradient(0deg, rgba(255,255,255,0) 10%, rgba(128,0,0,1)
 100%), 
    linear-gradient(rgba(0, 0, 0, 0.85),rgba(0, 0, 0, 0.85)), 
                url('https://troyerscountrymarket.com/wp-content/uploads/2020/07/Wood-background-footer.jpg'); */
  background-image: linear-gradient(
      to bottom,
      rgba(128, 0, 0, 1) 1%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url("https://troyerscountrymarket.com/wp-content/uploads/2020/07/Wood-background-footer.jpg");
  border-top: 1px solid #800000;
  border-bottom: 3px solid #000000;
  min-height: 300px;
  box-shadow: 0 10px 10px -8px #000;
}

.navbar .megamenu h6 {
  font-family: "Bree Serif", serif;
  font-size: 16px;
  text-shadow: 2px 2px #000000;
  margin-bottom: 0;
  cursor: pointer;
}

.nav {
  background: #800000;

  padding: 10px 0;
  min-height: 50px;
  width: 100%;
  box-shadow: 0 -10px 10px -8px #000;
  /*border-bottom: 1px solid #000000;*/
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-family: "Merriweather", serif;
  font-size: 16px;
  font-weight: 200;
}

.navbar-dark .navbar-nav .nav-link {
  color: #0d0d0d;
  text-transform: uppercase;
  margin: 0 20px;
  font-weight: 200;
  font-family: "Bree Serif", serif;
  opacity: 0.75;
}

.navbar-dark .navbar-nav .nav-link.active {
  border-bottom: 1px solid black;
  opacity: 1;
}

.navbar-dark .navbar-nav .nav-link:hover {
  opacity: 1;
  color: #000000;
}
.navbar-dark .navbar-nav .nav-link.active:hover {
  opacity: 1;
}

#header img {
  z-index: 1;
  position: relative;
  transform: translateY(1px);
  display: inline;
}

.bg-nav {
  border-top: 1px solid #d0d0d0;
  border-top: 2px solid #800000;
  z-index: 5;
  background: #800000;
  min-height: 50px;
  width: 100%;
  box-shadow: 0 -10px 10px -8px #000;
  /* border-bottom: 1px solid #000000; */
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-family: "Merriweather", serif;
  font-size: 16px;
  font-weight: 200;
}

.col-megamenu ul li {
  padding: 10px 0;
}

.flex {
  position: absolute;
  display: flex;
  width: 72%;
}
.justify-center {
  justify-content: center;
}
.mega-menu-item {
  flex-basis: 50%;
}
.col-megamenu img {
  bottom: unset !important;
  position: relative !important;
  width: 100%;
  border: 1px solid black;
  object-fit: cover;
}
.col-megamenu .img-link {
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}
.cat-title {
  background: #ffffff;
  background: url('https://www.homenish.com/wp-content/uploads/2021/01/Rustic-Barn-Wood-Wallpapers-1200x900.jpg');
  background-size: cover;
  background-position: top;
  color: #ffffff;
  padding: 10px 0;
  border: 1px solid black;
  cursor: pointer;
}
.col-megamenu {
  opacity: 0.8;
  cursor: pointer;
  transition-duration: 0.3s;
}
.col-megamenu:hover {
  opacity: 1;
}

@media only screen and (max-width: 1150px) {
  .navbar {
    height: 0px;
  }
}

@media only screen and (max-width: 500px) {
}

.navbar .megamenu {
  padding: 1rem;
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
    min-height: 40px;
  }
  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
}
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media (max-width: 768px) {
  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
  .navbar-collapse{
    margin-top: 20px;
  }
}
/* ============ mobile view .end// ============ */

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
</style>
