<template>
  <div class="red contained align-center">
    <a
      href="https://www.facebook.com/TroyersDutchMarket"
      target="_blank"
      title="Like Us on Facebook"
      class="like-us"
    >
      <img
        style="border-radius: 10px"
        width="250"
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxF0Juu4xKQ4QmBer9dbNCoarOphvKWAb_WPdZuGg8RB2TNX1zsPJr2midey6-l6Xr6w&usqp=CAU"
    /></a>
    <h5 style="margin-top: 30px">
      Follow us on Facebook to hear the latest news and be the first to find out
      about our
      <router-link class="white-link" to="/specials"
        >weekly specials</router-link
      >!
    </h5>
  </div>
</template>

<script>
export default {
  name: "LikeOnFBRow",
};
</script>

<style scoped>
.contained {
  margin: 0;
  padding: 120px 10%;
}
.contained > p:last-child {
  margin-bottom: 0;
}
.contained.red {
  background-color: rgba(128, 0, 0, 0.9);
  color: #ffffff;
}
.like-us {
  opacity: 0.7;
  margin-top: 0;
  transition-duration: 0.3s;
}
.like-us:hover {
  opacity: 1;
}
h5{
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 768px){
  .contained {
    margin: 0;
    padding: 80px 5%;
  }
  h5{
    font-size: 18px;
  }
}
</style>
