<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Troyer's Sandwich Trays</h3>
    </div>
    <div class="item-description">
      <p>Sandwichs made with our homemade sandwich buns or Nickles sub buns</p>
      <p>Three slices of Meat and One slice of Cheese per Sandwich</p>
    </div>
    <div class="itemsContainer">
      <div class="image-container">
        <img width="250" src="@/assets/subtray.png" />
      </div>
      <div class="itemContent">
        <h3>Tray Includes:</h3>
        <div class="item">
          <ul style="flex-basis: 60%">
            <li><h5>Meat</h5></li>
            <li>Virginia Ham or Honey Ham</li>
            <li>Honey Roasted Turkey or Smoked Turkey</li>
            <li>Salami or Sandwich Trail Bologna</li>
          </ul>
          <ul style="flex-basis: 40%">
            <li><h5>Cheese</h5></li>
            <li>Swiss</li>
            <li>Colby</li>
            <li>Marble</li>
            <li>Provolone</li>
            <li>Hot Pepper</li>
            <li>American</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="trays">
      <div class="tray-sizes">
        <table>
          <tbody>
            <tr>
              <td>12 Sandwiches</td>
            </tr>
            <tr>
              <td>$38.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>24 Sandwiches</td>
            </tr>
            <tr>
              <td>$63.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>36 Sandwiches</td>
            </tr>
            <tr>
              <td>$87.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>48 Sandwiches</td>
            </tr>
            <tr>
              <td>$110.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>60 Sandwiches</td>
            </tr>
            <tr>
              <td>$129.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubTray",
};
</script>

<style scoped>
.menuItemContainer {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("https://thumbs.dreamstime.com/b/corn-field-hand-drawn-agricultural-engraving-summer-autumn-maize-cobs-farm-house-silos-black-white-farmland-sketch-229058731.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.itemsContainer .image-container {
  order: 1;
  flex-basis: 40%;
  text-align: center;
}
.itemContent {
  order: 2;
  margin-left: 60px;
}
.itemsContainer {
  display: flex;
  margin-top: 80px;
  margin-bottom: 30px;
  align-items: center;
}

.itemContent {
  flex-basis: 60%;
  margin-left: 40px;
}

.tray-sizes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tray-sizes table {
  flex-basis: 20%;
  margin: 0 10px;
}
.item {
  display: flex;
}

li {
  margin: 10px 0 0 60px;
  padding-left: 10px;
  font-size: 14px;
}
li:first-child {
  list-style: none;
  margin: 10px 0;
  padding-left: 20px;
}

.trays table tr:first-child td {
  padding: 20px;
}


@media screen and (max-width: 768px){
  .itemsContainer{
    display: block;
    margin-top: 20px !important;
    margin-bottom: 60px;
  }
  .item{
    display: block;
  }
  .itemContent{
    margin-top: 50px;
    margin-left: 0;
  }
  .item ul{
    padding-left: 10px;
  }
  .item > ul:first-child{
    margin-top: 20px;
  }
  .tray-sizes{
    display: block;
  }
  .tray-sizes table{
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  li{
    margin: 10px 0 0 40px;
  }
  .itemsContainer .image-container{
    margin-left: 0;
  }
  .menuItemTitle h3{
    padding: 0 60px;
  }
  .item-description{
    margin-top: 120px;
  }
  .menuItemContainer{
    margin: 80px 0 80px;
  }
}
</style>
