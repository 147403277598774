<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Salads</h3>
    </div>
    <div class="item-description">
      <div class="item-top">
        <h4>All Salads: $4.99</h4>
      </div>
      <hr style="margin: 30px 0" />
      <div class="dressings">
        <h5>Dressings:</h5>
        <p style="margin-bottom: 0;">
          Ranch, Italian, Homemade BBQ, Homemade Poppy Seed, Homemade Sweet
          &amp; Sour, French
        </p>
      </div>
    </div>
    <div class="itemsContainer">
      <div class="item">
        <h3>BBQ Chicken Salad</h3>
        <p>
          Buffalo BBQ Chicken, Bacon Bits, Lettuce, Onions, Tomatoes, Shredded
          Cheddar Cheese, Homemade BBQ Dressing
        </p>
      </div>
      <div class="item">
        <h3>Chef Salad</h3>
        <p>
          Lettuce, Tomatoes, Onions, Croutons, Shredded Cheddar Cheese, Choice
          of Ham or Turkey &amp; Dressing
        </p>
      </div>
      <div class="item">
        <h3>Strawberry Pecan Salad</h3>
        <p style="margin-bottom: 5px">
          Lettuce, Onions, Roasted Pecans, Seseame Sticks, Strawberries,
          Shredded Mozzarella Cheese, Homemade Poppy Seed Dressing
        </p>
        <p style="color: rgba(128, 0, 0, 0.9)">
          Will substitute craisins when strawberries are out of season.
        </p>
      </div>
      <div class="item">
        <h3>Homemade Potato Salad</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Salads",
};
</script>

<style scoped>
.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
.itemsContainer > .item:nth-child(odd) {
  padding-right: 60px;
}
.itemsContainer > .item:nth-child(even) {
  padding-left: 60px;
}
.item {
  flex-basis: 50%;
  text-align: justify;
}
.item-top {
  text-align: center;
}
.item-description h5 {
  font-family: "Amatic SC", cursive;
  font-size: 30px;
}
.toppings,
.dressings {
  margin-top: 20px;
  text-align: left;
}

@media screen and (max-width: 768px){
  .itemsContainer{
    display: block;
  }
  .itemsContainer > .item:nth-child(odd){
    padding-right: 0;
  }
  .itemsContainer > .item:nth-child(even){
    padding-left: 0;
  }
}
</style>
