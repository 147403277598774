<template>
  <div id="main">
    <div id="pie_landing">
      <div class="pie parallax"></div>

      <div style="position: relative">
        <a id="see-sugar-free" href="#sugar-free-pies">
          <span id="pie-link" >Sugar Free Pies</span>
          <span class="right-arrow"><i class="fas fa-arrow-circle-down"></i></span>
        </a>
      </div>

      <div class="menuItemContainer">
        <div class="menuItemTitle">
          <h3>9inch Pies</h3>
        </div>

        <div style="margin-top: 80px" class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Cherry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Cherry Bomb</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>BlueBerry</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Blackberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Raspberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Pecan</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Elderberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Black Raspberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Red Raspberry Crème</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Raspberry Cheesecake</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Cherry Cheesecake</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Lemon Cheesecake</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Plain Cheesecake</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Rhubarb</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Rhubarb Crumb</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Apple</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Dutch Apple</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Peach</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Raisin</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>German Chocolate</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Oatmeal</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Lemon Meringue</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Coconut Crème</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Banana Crème</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Butterscotch Crème</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Peanutbutter Créme</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Chocolate Crème</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Chocolate Peanutbutter Crème</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Pumpkin</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Custard</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Blueberry Cheesecake</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Razzleberry<br />Triple Berry</h3>
            </div>
          </div>

          <div class="pie_item hidden"></div>
        </div>
      </div>
      <a id="sugar-free-pies"></a>
      <div style="margin-top: 140px" class="menuItemContainer">
        <div class="menuItemTitle">
          <h3>Sugar Free Pies</h3>
        </div>

        <div style="margin-top: 80px" class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Apple</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Cherry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Peach Pineapple</h3>
            </div>
          </div>
        </div>
        <div class="pie_row">
          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Blueberry</h3>
            </div>
          </div>

          <div class="pie_item">
            <div class="pie_img_container">
              <img height="100" src="@/assets/pie_icon.png" />
            </div>
            <div class="pie_info_container">
              <h3>Three Berry</h3>
            </div>
          </div>

          <div class="pie_item hidden"></div>
        </div>
      </div>
    </div>

    <CTA style="margin-top: 120px" />
    <OrderNow />
    <ScrollTopArrow />
  </div>
</template>

<script>

import OrderNow from "@/components/OrderNowComponent.vue";
import ScrollTopArrow from "@/components/ScrollTopArrow.vue";
import CTA from "@/components/Jumbotrons/pie_landingCTA.vue";

export default {
  name: "ninePie",
  components: {
    OrderNow,
    ScrollTopArrow,
    CTA,
  },
};
</script>

<style scoped>
.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
.bottom-right{
  bottom: 90px;
}
.itemsContainer > .item:nth-child(odd) {
  padding-right: 60px;
}
.itemsContainer > .item:nth-child(even) {
  padding-left: 60px;
}
.item {
  flex-basis: 50%;
  text-align: justify;
}
li {
  margin: 20px 0 0 50px;
  padding-left: 10px;
  font-size: 14px;
}
ul {
  padding-left: 0 !important;
}
.pie.parallax {
  background-image: url("https://images3.alphacoders.com/905/thumb-1920-905573.jpg");
  margin: 60px 0;
}

#pie-link{
  color: #800000;
  font-family: 'Merriweather', serif;
  font-size: 14px;
  transition-duration: 0.3s;
}
#pie-link:hover{
  transform: translateX(-15px);
}
#see-sugar-free{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: -35px;
  cursor: pointer;
  text-decoration: none;
}
.right-arrow{
  display: inline;
  margin-left: 10px;
}
.right-arrow .fas{
  opacity: 0.9;
  color: #800000;
}
</style>
