<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Troyer's Condiment Tray</h3>
    </div>
    <div class="item-description" style="display: none"></div>
    <div class="itemsContainer" style="margin-top: 100px">
      <div class="image-container" style="flex-basis: 33%">
        <img width="250" src="@/assets/CondimentTray.jpg" />
      </div>
      <div class="itemContent">
        <h3>Tray Includes:</h3>
        <div class="item">
          <ul>
            <li>Tomatoes</li>
            <li>Pickles</li>
            <li>Mayo</li>
            <li>Mustard</li>
            <li>Lettuce</li>
            <li>Onions</li>
            <li>Ketchup</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="trays">
      <div class="tray-sizes">
        <table>
          <tbody>
            <tr>
              <td>Extra Small<br /><span class="serving">Serves 9-12</span></td>
            </tr>
            <tr>
              <td>$14.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Small<br /><span class="serving">Serves 15-20</span></td>
            </tr>
            <tr>
              <td>$19.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Medium<br /><span class="serving">Serves 30-35</span></td>
            </tr>
            <tr>
              <td>$28.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Large<br /><span class="serving">Serves 35-40</span></td>
            </tr>
            <tr>
              <td>$30.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                Extra Large<br /><span class="serving">Serves 50-55</span>
              </td>
            </tr>
            <tr>
              <td>$33.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubTray",
};
</script>

<style scoped>
.itemsContainer .image-container {
  order: 1;
  flex-basis: 50%;
  margin-left: 15%;
  text-align: center;
}
.itemContent {
  order: 2;
  margin-left: 60px;
}
.itemsContainer {
  display: flex;
  margin-top: 60px;
  margin-bottom: 80px;
  align-items: center;
}

.itemContent {
  flex-basis: 50%;
  margin-left: 15%;
}

.tray-sizes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tray-sizes table {
  flex-basis: 20%;
  margin: 0 10px;
}
li {
  margin: 10px 0 0 60px;
  padding-left: 10px;
  font-size: 14px;
}
.trays {
  margin-bottom: 120px;
}
ul {
  padding-left: 0 !important;
}

@media screen and (max-width: 768px){
  .itemsContainer{
    display: block;
    margin-top: 140px !important;
    margin-bottom: 60px;
  }
  .item{
    display: block;
  }
  .itemContent{
    margin-top: 50px;
    margin-left: 20px;
  }
  .item ul{
    padding-left: 10px;
  }
  .item > ul:first-child{
    margin-top: 20px;
  }
  .tray-sizes{
    display: block;
  }
  .tray-sizes table{
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  li{
    margin: 10px 0 0 40px;
  }
  .itemsContainer .image-container{
    margin-left: 0;
  }
  .menuItemTitle h3{
    padding: 0 40px;
  }
}
</style>
