<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Massillon Tiger Tray</h3>
    </div>
    <div class="item-description" style="display: none"></div>
    <div class="itemsContainer" style="margin-top: 100px">
      <div class="image-container" style="flex-basis: 33%">
        <img width="250" src="@/assets/panther-tiger.png" />
      </div>
      <div class="itemContent">
        <h3>Tray Includes:</h3>
        <div class="item">
          <ul>
            <li>Select Choice of Meat and Cheese</li>
          </ul>
        </div>
      </div>
      <div class="tiger"></div>
    </div>

    <div class="trays">
      <div class="tray-sizes">
        <table>
          <tbody>
            <tr>
              <td>Extra Small<br /><span class="serving">Serves 9-12</span></td>
            </tr>
            <tr>
              <td>$27.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Small<br /><span class="serving">Serves 15-20</span></td>
            </tr>
            <tr>
              <td>$39.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Medium<br /><span class="serving">Serves 30-35</span></td>
            </tr>
            <tr>
              <td>$62.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Large<br /><span class="serving">Serves 35-40</span></td>
            </tr>
            <tr>
              <td>$72.00</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                Extra Large<br /><span class="serving">Serves 50-55</span>
              </td>
            </tr>
            <tr>
              <td>$87.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MassillonTigerTray",
};
</script>

<style scoped>
.menuItemContainer {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("https://mpng.pngfly.com/20180322/irw/kisspng-bengal-tiger-stock-photography-white-tiger-tiger-5ab43da3882944.4474109215217616995577.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.itemsContainer .image-container {
  order: 1;
  flex-basis: 50%;
  margin-left: 15%;
  text-align: center;
}
.itemContent {
  order: 2;
  margin-left: 60px;
}
.itemsContainer {
  display: flex;
  margin-top: 60px;
  margin-bottom: 80px;
  align-items: center;
  position: relative;
}

.itemContent {
  flex-basis: 50%;
  margin-left: 15%;
}

.tray-sizes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tray-sizes table {
  flex-basis: 20%;
  margin: 0 10px;
}
li {
  margin: 10px 0 0 60px;
  padding-left: 10px;
  font-size: 14px;
}
ul {
  padding-left: 0 !important;
}
.trays {
  margin-bottom: 120px;
}
.tiger {
  background-image: url("https://www.pinclipart.com/picdir/big/487-4872092_forest-background-clipart.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 1100px;
  height: 1100px;
  transform: scale(0.2);
  bottom: -561px;
  right: -230px;
  z-index: -2;
  display: none;
}

@media screen and (max-width: 768px){
  .itemsContainer .image-container{
    margin-left: 0;
  }
  .itemsContainer{
    display: block;
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .item{
    display: block;
  }
  .itemContent{
    margin-top: 50px;
    margin-left: 20px;
  }
  .item ul{
    padding-left: 10px;
  }
  .item > ul:first-child{
    margin-top: 20px;
  }
  .tray-sizes{
    display: block;
  }
  .tray-sizes table{
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .menuItemTitle h3{
    padding: 0 20px;
  }
  li{
    margin: 10px 0 0 40px;
  }
  .menuItemContainer {
    background-position: center 430px;
  }
}
</style>
