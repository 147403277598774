<template>
  <div class="location">
    <div class="flex-content" style="padding-top: 80px">
      <div class="content left">
        <h3>Location</h3>
        <p>
          We are located in Perry Township on Lincoln Way E, situated
          conveniently between Massillon and Canton, Ohio.
        </p>
        <h3 style="margin-top: 20px">Hours</h3>
        <table>
          <tbody>
            <tr id="monday">
              <td>Monday:</td>
              <td>09:00am - 05:00pm</td>
            </tr>
            <tr id="tuesday">
              <td>Tuesday:</td>
              <td>09:00am - 05:00pm</td>
            </tr>
            <tr id="wednesday">
              <td>Wednesday:</td>
              <td>09:00am - 05:00pm</td>
            </tr>
            <tr id="thursday">
              <td>Thursday:</td>
              <td>09:00am - 05:00pm</td>
            </tr>
            <tr id="friday">
              <td>Friday:</td>
              <td>09:00am - 05:00pm</td>
            </tr>
            <tr id="saturday">
              <td>Saturday:</td>
              <td>09:00am - 03:30pm</td>
            </tr>
            <tr id="sunday">
              <td>Sunday:</td>
              <td>CLOSED</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="iframe-desktop" style="flex-basis: 50%; min-height: 350px">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.5181736044456!2d-81.47140138467465!3d40.79460574049496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836d9cd7fc3f7b7%3A0x24df2dfee936577c!2sTroyer&#39;s%20Dutch%20Market!5e0!3m2!1sen!2sus!4v1635622606531!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div class="iframe-mobile">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12082.073375132259!2d-81.469213!3d40.794602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24df2dfee936577c!2sTroyer&#39;s%20Dutch%20Market!5e0!3m2!1sen!2sus!4v1641349769243!5m2!1sen!2sus" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
   </div>
  </div>
</template>

<script>
export default {
  name: "LocationRow",
  mounted() {
    var d = new Date();
    console.log(d.getDay());
    switch (d.getDay()) {
      case 0:
        var sunday = document.getElementById("sunday");
        sunday.classList.toggle("active");
        break;
      case 1:
        var monday = document.getElementById("monday");
        monday.classList.toggle("active");
        break;
      case 2:
        var tuesday = document.getElementById("tuesday");
        tuesday.classList.toggle("active");
        break;
      case 3:
        var wednesday = document.getElementById("wednesday");
        wednesday.classList.toggle("active");
        break;
      case 4:
        var thursday = document.getElementById("thursday");
        thursday.classList.toggle("active");
        break;
      case 5:
        var friday = document.getElementById("friday");
        friday.classList.toggle("active");
        break;
      case 6:
        var saturday = document.getElementById("saturday");
        saturday.classList.toggle("active");
        break;
      default:
        // do nothing
        break;
    }
  },
};
</script>

<style scoped>
.location {
  width: 100%;
  height: 500px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("https://images.squarespace-cdn.com/content/v1/5fc4e3bd084698658e77ce95/1615656578223-QYIBY17UDDWXPTU9XJQ5/Victorias_Stories_colurful_travel_prints_shop_photo_prints_arrow2v2.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}
.flex-content {
  margin-top: 20px;
}
.location .content {
  text-align: center;
}
table {
  margin: 0 auto;
  width: 70%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  opacity: 0.7;
}
table tr > td:first-child {
  text-align: right;
  padding-right: 10px;
}
table tr > td:last-child {
  text-align: left;
  padding-left: 10px;
}
table td {
  padding-bottom: 5px;
}
#sunday,
#monday,
#tuesday,
#wednesday,
#thursday,
#friday,
#saturday {
  opacity: 0.8;
}
#sunday.active,
#monday.active,
#tuesday.active,
#wednesday.active,
#thursday.active,
#friday.active,
#saturday.active {
  font-weight: 900;
  opacity: 1;
}

.iframe-desktop{
    display: block;
  }
  .iframe-mobile{
    display: none;
  }

@media screen and (max-width: 768px){
  .location {
    height: unset;
    margin-bottom: 80px !important;
    background-position: top;
  }
  .flex-content {
    margin-top: 20px;
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .location .content > h3:first-child,
  .location .content p{
    text-align: left;
  }
  .location .content p{
    margin-bottom: 40px;
  }
  table {
    width: 100%;
    margin-bottom: 30px;
  }
  .iframe-desktop{
    display: none;
  }
  .iframe-mobile{
    display: block;
  }
}
</style>
