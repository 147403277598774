<template>
  <div id="orderNow" >
    <router-link to="/contact-us" 
       class="superBTN bottom-left"  
       data-tooltip="Order Now"
       data-tooltip-location="left">
      <span class="order"><i class="fas fa-phone"></i></span>
    </router-link> 
  </div>
</template>


<style scoped>
.bottom-left {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 500;
  background-color: rgba(128, 0, 0, 0.9);
  border: 2px solid rgba(128, 0, 0, 0.9);
  padding: 7px 11px;
  border-radius: 50%;
  transition-duration: 0.2s;
}
a:hover {
  background-color: #ffffff;
  border: 2px solid rgba(128, 0, 0, 1);
  animation: unset;
}
.fas {
  font-size: 22px;
  color: #ffffff;
  padding: 10px 5px;
  transition-duration: 0.2s;
}
.fas:hover {
  color: rgba(165, 78, 78, 0.9);
}

@media screen and (max-width: 768px){
  .fas{
    font-size: 15px;
  }
}
</style>
