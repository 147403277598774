<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Baked Goods</h3>
    </div>
    <div class="item-description">
      <h4>Sweets, Treats, and Yummies to Eat!</h4>
      <p style="margin-top: 20px">Got a sweet tooth? We have you covered!</p>
      <p>
        From our delicious
        <router-link class="red-link" to="/bakedgoods/cookies"
          >oven-baked cookies</router-link
        >
        to our fresh Troyer's Home Pantry pies, we have something that'll hit
        the spot! Stop by and check out our selection of baked goods or
        <router-link class="red-link" to="/contact-us">contact us</router-link>
        and order your pies today!
      </p>
    </div>
    <div class="pie-row">
      <div class="pie">
        <router-link class="img-link" to="/bakedgoods/cookies">
          <img
            height="185"
            width="585"
            style="object-position: bottom"
            src="https://scontent.fpit1-1.fna.fbcdn.net/v/t31.18172-8/14918830_881100558693100_6694438918575773646_o.jpg?_nc_cat=104&ccb=1-5&_nc_sid=9267fe&_nc_ohc=Zd-yAKxcu98AX_BVWdK&_nc_ht=scontent.fpit1-1.fna&oh=d2fe99b0b400ef3522677521caab652a&oe=61A2AC00"
          />
          <div class="cat-title">
            <h6 class="title">Cookies</h6>
          </div>
        </router-link>
      </div>
      <div class="pie">
        <router-link class="img-link" to="/bakedgoods/pastries-and-bread">
          <img
            height="185"
            width="585"
            style="object-position: bottom"
            src="https://www.supermarketperimeter.com/ext/resources/0806---pastries.jpg?t=1596750671&width=1080"
          />
          <div class="cat-title">
            <h6 class="title">Pastries and Bread</h6>
          </div>
        </router-link>
      </div>
    </div>
    <div class="pie-row">
      <div class="pie">
        <router-link class="img-link" to="/bakedgoods/six-inch-pies">
          <img
            height="185"
            width="585"
            style="object-position: top"
            src="https://scontent.fpit1-1.fna.fbcdn.net/v/t1.18169-9/27749995_1160654524071034_181113783875996108_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=730e14&_nc_ohc=gNPHm0fratIAX9RKOyt&_nc_ht=scontent.fpit1-1.fna&oh=1fb36d4adfaac337cfa41a8a8ec3b28f&oe=61A26D68"
          />
          <div class="cat-title">
            <h6 class="title">6inch Pies</h6>
          </div>
        </router-link>
      </div>
      <div class="pie">
        <router-link class="img-link" to="/bakedgoods/nine-inch-pies">
          <img
            height="185"
            width="585"
            style="object-position: top"
            src="https://scontent.fpit1-1.fna.fbcdn.net/v/t31.18172-8/17854810_980846992051789_1761571906859901970_o.jpg?_nc_cat=111&ccb=1-5&_nc_sid=9267fe&_nc_ohc=KtQhp1e6m6MAX8Uua4l&tn=p0qkiy4IwTiY3YEh&_nc_ht=scontent.fpit1-1.fna&oh=177d57bbbaf7bc035409886b71588666&oe=61A4DAC2"
          />
          <div class="cat-title">
            <h6 class="title">9inch Pies</h6>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pie",
};
</script>

<style scoped>
.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
.itemsContainer > .item:nth-child(odd) {
  padding-right: 60px;
}
.itemsContainer > .item:nth-child(even) {
  padding-left: 60px;
}
.item {
  flex-basis: 50%;
  text-align: justify;
}
li {
  margin: 20px 0 0 50px;
  padding-left: 10px;
  font-size: 14px;
}
ul {
  padding-left: 0 !important;
}

.pie-row {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.pie {
  flex-basis: 50%;
  margin: 0 20px;
  opacity: 0.8;
  cursor: pointer;
  transition-duration: 0.3s;
}
.pie:hover {
  opacity: 1;
}
.pie img {
  width: 100%;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  object-fit: cover;
}
.img-link {
  text-decoration: none;
  color: #000000;
  cursor: pointer !important;
}
.cat-title {
  background: #ffffff;
  padding: 10px 0;
  border: 1px solid black;
  background: url('https://www.homenish.com/wp-content/uploads/2021/01/Rustic-Barn-Wood-Wallpapers-1200x900.jpg');
  background-size: cover;
  background-position: top;
}
.title {
  font-family: "Bree Serif", serif;
  font-size: 24px;
  margin-bottom: 0;
  cursor: pointer;
  color: #ffffff;
  text-shadow: 2px 2px #000000;
}
</style>
