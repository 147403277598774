<template>
  <div id="main">
    <h1>Privacy Policy</h1>

    <section style="margin-top: 60px">
      <h2>Who we are</h2>
      <p>
        Our website address is:
        <a href="https://troyersdutchmarket.com/"
          >https://troyersdutchmarket.com/</a
        >
      </p>
    </section>
    <section style="margin-bottom: 20px">
      <h2>What personal data we collect and why we collect it</h2>
      <p>
        We collect information from you when you register on our site, place an
        order, subscribe to our newsletter or fill out a form.
      </p>

      <p>
        When ordering or registering on our site, as appropriate, you may be
        asked to enter your: name, e-mail address or mailing address. You may,
        however, visit our site anonymously.
      </p>
    </section>
    <section class="sub">
      <h3>Comments</h3>
      <p>
        When visitors leave comments on the site we collect the data shown in
        the comments form, and also the visitor’s IP address and browser user
        agent string to help spam detection.
      </p>

      <p>
        An anonymized string created from your email address (also called a
        hash) may be provided to the Gravatar service to see if you are using
        it. The Gravatar service privacy policy is available here:
        https://automattic.com/privacy/. After approval of your comment, your
        profile picture is visible to the public in the context of your comment.
      </p>
    </section>
    <section class="sub">
      <h3>Cookies</h3>
      <p>
        If you leave a comment on our site you may opt-in to saving your name,
        email address and website in cookies. These are for your convenience so
        that you do not have to fill in your details again when you leave
        another comment. These cookies will last for one year.
      </p>

      <p>
        If you visit our login page, we will set a temporary cookie to determine
        if your browser accepts cookies. This cookie contains no personal data
        and is discarded when you close your browser.
      </p>

      <p>
        When you log in, we will also set up several cookies to save your login
        information and your screen display choices. Login cookies last for two
        days, and screen options cookies last for a year. If you select
        “Remember Me”, your login will persist for two weeks. If you log out of
        your account, the login cookies will be removed.
      </p>

      <p>
        If you edit or publish an article, an additional cookie will be saved in
        your browser. This cookie includes no personal data and simply indicates
        the post ID of the article you just edited. It expires after 1 day.
      </p>
    </section>
    <section class="sub">
      <h3>Media</h3>
      <p>
        If you upload images to the website, you should avoid uploading images
        with embedded location data (EXIF GPS) included. Visitors to the website
        can download and extract any location data from images on the website.
      </p>
    </section>
    <section style="margin-top: 60px">
      <h2>What do we use your information for?</h2>
      <p>
        Any of the information we collect from you may be used in one of the
        following ways:
      </p>
      <ul>
        <li>
          To personalize your experience (your information helps us to better
          respond to your individual needs)
        </li>
        <li>
          To improve our website (we continually strive to improve our website
          offerings based on the information and feedback we receive from you)
        </li>
        <li>
          To improve customer service (your information helps us to more
          effectively respond to your customer service requests and support
          needs)
        </li>
        <li>
          To process transactions (Your information, whether public or private,
          will not be sold, exchanged, transferred, or given to any other
          company for any reason whatsoever, without your consent, other than
          for the express purpose of delivering the purchased product or service
          requested.)
        </li>
        <li>
          To administer a contest, promotion, survey or other site feature
        </li>
        <li>
          To send periodic emails (The email address you provide for order
          processing, may be used to send you information and updates pertaining
          to your order, in addition to receiving occasional company news,
          updates, related product or service information, etc.)
        </li>
      </ul>
    </section>
    <section>
      <h2>How long do we retain your data?</h2>
      <p>
        If you leave a comment, the comment and its metadata are retained
        indefinitely. This is so we can recognize and approve any follow-up
        comments automatically instead of holding them in a moderation queue.
      </p>
      <p>
        For users that register on our website (if any), we also store the
        personal information they provide in their user profile. All users can
        see, edit, or delete their personal information at any time (except they
        cannot change their username). Website administrators can also see and
        edit that information.
      </p>
    </section>
    <section>
      <h2>Do we disclose any information to outside parties?</h2>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your
        personally identifiable information. This does not include trusted third
        parties who assist us in operating our website, conducting our business,
        or servicing you, so long as those parties agree to keep this
        information confidential.
      </p>

      <p>
        We may also release your information when we believe release is
        appropriate to comply with the law, enforce our site policies, or
        protect ours or others rights, property, or safety. However,
        non-personally identifiable visitor information may be provided to other
        parties for marketing, advertising, or other uses.
      </p>
    </section>
    <section>
      <h2>Embedded content from other websites</h2>
      <p>
        Articles on this site may include embedded content (e.g. videos, images,
        articles, etc.). Embedded content from other websites behaves in the
        exact same way as if the visitor has visited the other website.
      </p>

      <p>
        These websites may collect data about you, use cookies, embed additional
        third-party tracking, and monitor your interaction with that embedded
        content, including tracking your interaction with the embedded content
        if you have an account and are logged in to that website.
      </p>
    </section>
    <section>
      <h2>What rights you have over your data</h2>
      <p>
        If you have an account on this site, or have left comments, you can
        request to receive an exported file of the personal data we hold about
        you, including any data you have provided to us. You can also request
        that we erase any personal data we possess.
      </p>
    </section>
    <section>
      <h2>Where we send your data</h2>
      <p>
        Visitor comments may be checked through an automated spam detection
        service.
      </p>
    </section>
    <section style="text-align: center; margin-top: 100px">
      <h2 style="margin-bottom: 20px">Questions, Comments, Concerns</h2>
      <p>
        For any other questions, comments, or concerns regarding the integrity
        of <strong>this website</strong>, please contact the developer
        <router-link to="/report">here</router-link>.
      </p>
      <p>
        For any other questions, comments, or concerns regarding the integrity
        of <strong>Troyer's Dutch Market</strong>, please contact the business
        <router-link to="/contact-us">here</router-link>.
      </p>
    </section>
  </div>
</template>

<style scoped>
h1 {
  font-size: 50px;
  border-bottom: 1px solid #800000;
  padding-bottom: 20px;
  margin-bottom: 40px;
}
h2,
h3 {
  text-transform: capitalize;
}
section {
  margin: 40px 0 60px;
  text-align: justify;
}
section.sub {
  margin: 30px 0;
}
li {
  padding: 7px 0 0 20px;
}
a {
  color: #800000;
  opacity: 0.8;
  text-decoration: none;
}
a:hover {
  opacity: 1;
  text-decoration: underline;
}
strong {
  font-weight: 900;
}
</style>
