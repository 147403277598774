<template>
  <div class="menuItemContainer">
    <div class="menuItemTitle">
      <h3>Wraps</h3>
    </div>
    <div class="item-description">
      <div class="item-top">
        <h4>All Wraps: $5.99</h4>
        <h4>Extra Meat: $1.00</h4>
      </div>
      <hr style="margin: 30px 0" />
      <div class="toppings">
        <h5>Toppings:</h5>
        <p>
          Lettuce, Tomatoes, Onions, Banana Peppers, Jalape&ntilde;o Peppers,
          Pickles, Hard Boiled Eggs, <br />Ketchup, Mustard, Mayo, Honey
          Mustard, Horse Radish Sauce
        </p>
      </div>
      <div class="dressings">
        <h5>Dressings:</h5>
        <p style="margin-bottom: 0;">
          Ranch, Italian, Homemade BBQ, Homemade Poppy Seed, Homemade Sweet
          &amp; Sour, French
        </p>
      </div>
    </div>
    <div class="itemsContainer">
      <div class="item">
        <h3>Troyer's Special Wrap</h3>
        <p>
          Turkey Breast, Ham, Lacy Baby Swiss Cheese, choice of dressing &amp;
          toppings
        </p>
      </div>
      <div class="item">
        <h3>Italian Wrap</h3>
        <p>
          Hard Salami, Pepperoni, Ham, Provolone Cheese, Italian dressing &amp;
          choice of toppings
        </p>
      </div>
      <div class="item">
        <h3>Ham Wrap</h3>
        <p>Ham, Swiss Cheese, choice of dressing &amp; toppings</p>
      </div>
      <div class="item">
        <h3>Fried Chicken Wrap</h3>
        <p>
          Fried Chicken Breast, Havarti Cheese, Homemade BBQ dressing &amp;
          choice of toppings
        </p>
      </div>
      <div class="item">
        <h3>Trail &amp; Swiss</h3>
        <p>Trail Bologna, Swiss Cheese, choice of dressing &amp; toppings</p>
      </div>
      <div class="item">
        <h3>Roast Beef Wrap</h3>
        <p>
          Roast Beef, Swiss Cheese, Homemade BBQ dressing &amp; choice of
          toppings
        </p>
      </div>
      <div class="item">
        <h3>Turkey Bacon Ranch Wrap</h3>
        <p>
          Turkey Breast, Bacon, Marble Cheese, Ranch dressing &amp; choice of
          toppings
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Wraps",
};
</script>

<style scoped>
.option-row {
  display: flex;
  margin: 40px 0;
}
.option {
  flex-basis: 50%;
  margin: 0 13%;
  padding: 20px;
  background: #ffffcc;
  border-radius: 20px;
  border: 1px solid #ffe6b3;
}

.item h3 {
  font-family: "Amatic SC", cursive;
  font-size: 32px;
}

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
.itemsContainer > .item:nth-child(odd) {
  padding-right: 60px;
}
.itemsContainer > .item:nth-child(even) {
  padding-left: 60px;
}
.item {
  flex-basis: 50%;
  text-align: justify;
}
.item-top {
  display: flex;
  justify-content: space-around;
}
.item-description h3 {
  flex-basis: 50%;
}
.item-description h5 {
  font-family: "Amatic SC", cursive;
  font-size: 30px;
}
.toppings,
.dressings {
  margin-top: 20px;
  text-align: left;
}

@media screen and (max-width: 768px){
  .item-top {
    display: block;
  }
  .item-description .item-top > h4:first-child {
    margin-bottom: 10px;
  }
  .itemsContainer{
    display: block;
  }
  .itemsContainer > .item:nth-child(odd){
    padding-right: 0;
  }
  .itemsContainer > .item:nth-child(even){
    padding-left: 0;
  }
}
</style>
